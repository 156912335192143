import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix } from "../utils";
import getDateFromIso from "../utils/getDateFromIso";
import Helmet from "react-helmet";

export default class News extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
          <meta property="og:type" content="article" />
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      )
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
              <footer className="post-meta">
                <time
                  className="published"
                  dateTime={_.get(this.props, "pageContext.frontmatter.date")}
                >
                  {getDateFromIso(
                    _.get(this.props, "pageContext.frontmatter.date")
                  )}
                </time>
              </footer>
            </article>
          </div>
        </div>
      </Layout>
    );
  }
}
